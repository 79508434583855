import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
// import List from "../CompanyList/List";
// import MenuRecord from "../Record/MenuRecord";
// import DateTime from "../Record/DateTime/DateTime";
// import Service from "../Record/Service/Service";
// import Staff from "../Record/Staff/Staff";
// import Menu from "../Menu";
// import Order from "../Record/Order/Order";
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";
import Menu from "../Menu";
import Preloader from "../Preloader";
import PageHeader from "../PageHeader";


export class ProfileChange extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            name: '',
            date: '',
            email: '',
            btnPhone: false,
            btnCode: false,
            genderM: false,
            genderG: false,
            openExit: false,

        };

        this.changeName = this.changeName.bind(this);
        this.changeDate = this.changeDate.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
        this.changeGenderM = this.changeGenderM.bind(this);
        this.changeGenderG = this.changeGenderG.bind(this);
        this.openDelBlock = this.openDelBlock.bind(this);

    }

    changeGenderG(event) {
        const target = event.target;
        ;

        if (target.checked == true) {
            this.setState({
                genderM: false,
                genderG: true
            });
        } else {
            this.setState({
                genderM: false,
                genderG: false
            });
        }

    }

    changeGenderM(event) {
        const target = event.target;
        ;


        if (target.checked == true) {
            this.setState({
                genderM: true,
                genderG: false
            });
        } else {
            this.setState({
                genderM: false,
                genderG: true
            });
        }
    }

    changeName(event) {
        this.setState({name: event.target.value});
    }

    changeDate(event) {
        this.setState({date: event.target.value});
    }

    changeEmail(event) {
        this.setState({email: event.target.value});
    }

    renderButton() {
        let gender = false;
        if (this.state.genderM || this.state.genderG) {
            gender = true;
        }
        if (this.state.name && this.state.date && gender) {
            return (
                <div className='d-flex justify-content-center mt-3 mb-5'>
                    <div onClick={() => this.sender()}
                         className='p-3 bg-red chio-card d-inline-block white-text'>
                        Сохранить
                    </div>
                </div>
            )
        } else {
            return (
                <div className='d-flex justify-content-center mt-3 mb-5'>
                    <div className='p-3 bg-red chio-card d-inline-block white-text'>
                        Сохранить
                    </div>
                </div>
            )
        }
    }

    sender() {

        let data = {
            name: this.state.name,
            date: this.state.date,
            email: this.state.email,
            genderM: this.state.genderM,
            genderG: this.state.genderG,
        };
        axios({

            url: GlobalVariable.host() + '/mobile/profile/save',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data

            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                window.location.href = '/home';
                this.setState({})
            }).catch(error => {
            this.setState({auth: false, isLoad: true})

        })

    }

    exit() {

        let data = {};
        axios({

            url: GlobalVariable.host() + '/open/widget/v2/security/exit',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "GET",
            data: data

            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                window.location.href = '/exit';
                this.setState({})
            }).catch(error => {
            this.setState({auth: false, isLoad: true})

        })

    }

    postDelProfile() {

        let data = {};
        axios({

            url: GlobalVariable.host() + '/mobile/profile/delete',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "GET",
            data: data

            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status

                window.location.href = '/home';
                this.setState({})
            }).catch(error => {
            this.setState({auth: false, isLoad: true})

        })

    }

    openDelBlock() {
        this.setState({openChangePoint: true})
    }
    openExit() {
        this.setState({openExit: true})
    }
    renderChooseExitProfile() {
        if (this.state.openExit == true) {
            return (
                <div className={'changePointBlock'}>
                    <div className={'changePoint'}>
                        <div>Вы действительно хотите выйти?</div>

                        <div onClick={() => this.exit()} className={'btn-active mt-3 mb-3'}>
                            ДА
                        </div>
                        <div onClick={()=>this.setState({openExit: false})} className={'btn-active mt-3 mb-3'}>
                            НЕТ
                        </div>

                    </div>
                </div>
            )
        }

    }
    renderChooseDelProfile() {
        if (this.state.openChangePoint == true) {
            return (
                <div className={'changePointBlock'}>
                    <div className={'changePoint'}>
                        <div>Вы действительно хотите удалить свой профиль? (Все ваши баллы удалятся)</div>

                        <div onClick={() => this.postDelProfile()} className={'btn-active mt-3 mb-3'}>
                            ДА
                        </div>
                        <div className={'btn-active mt-3 mb-3'}>
                            НЕТ
                        </div>

                    </div>
                </div>
            )
        }

    }

    componentDidMount() {


        axios({

            url: GlobalVariable.host() + '/mobile/profile/show',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "GET",

            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status

                this.setState({
                    name: result.data.name,
                    date: result.data.date,
                    email: result.data.email,
                    isLoad: true,
                    auth: true,
                    info: result.data,
                    genderM: result.data.sex == 1 ? true : false,
                    genderG: result.data.sex == 2 ? true : false,
                })
            }).catch(error => {
            this.setState({auth: false, isLoad: false})
            window.location.href = '/login';
        })

    }


    render() {
        if (!this.state.isLoad) {
            return (

                <Preloader></Preloader>
            )
        } else {

            return (
                <div className='main profile'>
                    <div className='content mx-w-content'>
                        <PageHeader
                            backBtnUrl='/'
                            pageTitle='Профиль'/>
                        <div className='pt-header'></div>

                        <div className='ml-3 mr-3'>
                            <div className='txt-large mb-3'>Изменение профиля</div>

                            {/*Фото профиля*/}
                            {/*<div className='ml-3 txt-middle'>Фото профиля</div>*/}
                            {/*<input type='file'*/}
                            {/*       className='txt-middle mb-3 bold'/>*/}

                            {/*Имя*/}
                            <div className='ml-3 txt-middle'>Имя</div>
                            <input value={this.state.name}
                                   placeholder={'Имя'}
                                   onChange={this.changeName}
                                   className='txt-input pl-3 pr-3 pt-2 pb-2 txt-large mb-3'/>

                            {/*Дата рождения*/}
                            <div className='ml-3 txt-middle'>Дата рождения</div>
                            <input type="date" value={this.state.date}
                                   onChange={this.changeDate}
                                   className='txt-input pl-3 pr-3 pt-2 pb-2 txt-large mb-3'/>

                            {/*Пол*/}
                            <div className='ml-3 txt-middle'>Пол</div>
                            <div className='mb-3 d-flex'>
                                <div className='ml-4'>
                                    <input
                                        checked={this.state.genderM}
                                        onChange={this.changeGenderM}
                                        name="m"
                                        type="checkbox"
                                        className="form-check-input"
                                        value=""/>Мужской
                                </div>
                                <div className='ml-5'>
                                    <input
                                        checked={this.state.genderG}
                                        onChange={this.changeGenderG}
                                        name="g"
                                        type="checkbox"
                                        className="form-check-input"
                                        value=""/>Женский
                                </div>
                            </div>

                            {/*Email*/}
                            <div className='ml-3 txt-middle'>Email</div>
                            <input value={this.state.email} placeholder={'Email'}
                                   onChange={this.changeEmail}
                                   className={'txt-input pl-3 pr-3 pt-2 pb-2 txt-large mb-2'}/>

                            {/*Сохранить*/}
                            {this.renderButton()}
                        </div>

                        {/*О приложении*/}
                        <div className='ml-3 mr-3 '>
                            <div className='txt-large mb-1'>Приложение</div>
                            <div className='mb-3'>Версия: 2.1</div>

                            <div className='d-flex justify-content-center'>
                                <div onClick={() => this.openExit()}
                                     className='text-center chio-card p-3 txt-large w-50 mr-1 d-flex justify-content-center align-items-center'>
                                    Выход
                                </div>
                                {/*<div onClick={() => this.openDelBlock()}*/}
                                {/*     className='red-text bold text-center chio-card p-3 txt-middle w-50 ml-1 d-flex justify-content-center align-items-center'>*/}
                                {/*    Удалить мою учетную запись*/}
                                {/*</div>*/}
                            </div>
                            {/*{this.renderChooseDelProfile()}*/}
                            {this.renderChooseExitProfile()}
                        </div>
                    </div>
                    <Menu></Menu>
                </div>
            )
        }


    }
}

export default ProfileChange;