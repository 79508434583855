import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
// import List from "../CompanyList/List";
// import MenuRecord from "../Record/MenuRecord";
// import DateTime from "../Record/DateTime/DateTime";
// import Service from "../Record/Service/Service";
// import Staff from "../Record/Staff/Staff";
// import Menu from "../Menu";
// import Order from "../Record/Order/Order";
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";
import Menu from "../Menu";
import PageHeader from "../PageHeader";


export class WantToWork extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            chat: []

        };

        this.changePhone = this.changePhone.bind(this);
        this.changeName = this.changeName.bind(this);
        this.changeDate = this.changeDate.bind(this);
        this.changeEmail = this.changeEmail.bind(this);


    }


    changePhone(event) {
        this.setState({name: event.target.value});
    }

    changeName(event) {
        this.setState({name: event.target.value});
    }

    changeDate(event) {
        this.setState({date: event.target.value});
    }

    changeEmail(event) {
        this.setState({email: event.target.value});
    }


    componentDidMount() {
    }


    render() {
        return (
            <div className='main want-to-work'>
                <div className='content mx-w-content'>
                    <PageHeader
                        pageTitle='Форма заявки'
                        backBtnUrl='/start'/>
                    <div className='pt-header'></div>

                    <div className='chio-card-subheader ml-3 mr-3 pl-3 pr-3 pt-2'>
                        Хочу работать в Чио Чио
                    </div>

                    {/*Показать в начале*/}
                    <div className='chio-card ml-3 mb-2 mr-3 pl-3 pr-3 pt-2 pb-2'>
                        <div className='ml-3 txt-middle mt-2 mb-1'>ФИО</div>
                        <input value={this.state.name}
                               placeholder={'Введите ФИО'}
                               onChange={this.changeName}
                               className='txt-input pl-3 pr-3 pt-2 pb-2 txt-large mb-3 bg-white font-weight-normal'/>


                        <div className='ml-3 txt-middle mt-2 mb-1'>Телефон</div>
                        <input value={this.state.phone}
                               placeholder={'Введите номер'}
                               onChange={this.changePhone}
                               className='txt-input pl-3 pr-3 pt-2 pb-2 txt-large mb-3 bg-white font-weight-normal'/>

                        <div className='ml-3 txt-middle mt-2 mb-1'>Выберите город</div>
                        <select value={this.state.town}
                                placeholder={''}
                                onChange={this.changePhone}
                                className='txt-input pl-3 pr-3 pt-2 pb-2 txt-large mb-3 bg-white font-weight-normal'>
                            <option value='1'>1</option>
                            <option value='2'>2</option>
                            <option value='3'>3</option>
                        </select>

                        <div className='ml-3 txt-middle mt-2 mb-1'>Комментарий</div>
                        <textarea value={this.state.phone}
                                  placeholder={''}
                                  rows={4}
                                  onChange={this.changePhone}
                                  className='txt-input pl-3 pr-3 pt-2 pb-2 txt-large mb-3 bg-white font-weight-normal'/>

                        {/*Пол*/}
                        <div className='ml-3 mr-3 mb-3'>
                            Нажимая «Отправить», вы принимаете <Link to={'/'}>Пользовательское соглашение</Link> и <Link
                            to={'/'}>Политику конфиденциальности</Link>
                        </div>


                        <div className='chio-card bg-red text-white pt-3 pb-3 pl-5 pr-5 d-table m-auto mb-3'>
                            Отправить
                        </div>
                    </div>

                    {/*Показать если успешно отправлено*/}
                    <div className='chio-card ml-3 mb-2 mr-3 pl-3 pr-3 pt-2 pb-2'>
                        <div className='text-center txt-middle mt-3 mb-3'
                             style={{color: 'green'}}>
                            Данные успешно отправлены
                        </div>
                    </div>

                    {/*Показать если ошибка сервера*/}
                    <div className='chio-card ml-3 mb-2 mr-3 pl-3 pr-3 pt-2 pb-2'>
                        <div className='text-center red-text txt-middle mt-3 mb-3'>
                            Неизвестная ошибка<br/>
                            Мы уже работаем над ее устранением,<br/>
                            Повторите попытку чуть позже
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default WantToWork;