import './Modal.css'

import React from "react";

export default function Modal({title, content, onClick}) {
    return (
        <div className='Modal'>
            <div className='d-flex justify-content-between p-3 pt-5'>
                <div className='txt-large'>{title}</div>
                <div onClick={onClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor"
                         className="bi bi-x-lg" viewBox="0 0 16 16">
                        <path
                            d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
                    </svg>
                </div>
            </div>
            <div className='p-3'>
                {content}
            </div>
        </div>
    )
}