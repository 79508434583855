import React, {Component} from 'react';
import {
    Link
} from "react-router-dom";

import Menu from "../../Menu";
import PageHeader from "../../PageHeader";

export class KnowledgeBase extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className='main knowledge-base'>
                <div className='content mx-w-content'>
                    <PageHeader
                        pageTitle='Список уроков'
                        backBtnUrl='/knowledge-base'/>
                    <div className='pt-header'></div>

                    <div className='ml-3 mr-3 mb-2'>
                        <Link to='/knowledge-base/category/lesson2' className='chio-card'>
                            <img className='card-img-left mr-2' src='/img/events/img-event1.jpg' alt=''/>
                            <div className='p-3 d-flex flex-column justify-content-between' style={{width:'70%'}}>
                                <h2 className='mb-0 txt-middle'>Этапы обслуживания</h2>
                                <div className='d-flex align-items-center'>
                                    <img className='ico' src='/img/knowledge-base/ico-clock-red.svg' alt=''/>
                                    <span className='ml-2 txt-small'>3 минуты</span>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className='ml-3 mr-3 mb-2'>
                        <Link to='/knowledge-base/category/lesson3' className='chio-card'>
                            <img className='card-img-left mr-2' src='/img/events/img-event1.jpg' alt=''/>
                            <div className='p-3 d-flex flex-column justify-content-between' style={{width:'70%'}}>
                                <h2 className='mb-0 txt-middle'>Вопросы клиенту для повышения чека.</h2>
                                <div className='d-flex align-items-center'>
                                    <img className='ico' src='/img/knowledge-base/ico-clock-red.svg' alt=''/>
                                    <span className='ml-2 txt-small'>3 минуты</span>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className='ml-3 mr-3 mb-2'>
                        <Link to='/knowledge-base/category/lesson4' className='chio-card'>
                            <img className='card-img-left mr-2' src='/img/lesson/lesson4.png' alt=''/>
                            <div className='p-3 d-flex flex-column justify-content-between' style={{width:'70%'}}>
                                <h2 className='mb-0 txt-middle'>Блистательный сервис - 1 часть.</h2>
                                <div className='d-flex align-items-center'>
                                    <img className='ico' src='/img/knowledge-base/ico-clock-red.svg' alt=''/>
                                    <span className='ml-2 txt-small'>2 минуты</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className='ml-3 mr-3 mb-2'>
                        <Link to='/knowledge-base/category/lesson5' className='chio-card'>
                            <img className='card-img-left mr-2' src='/img/lesson/lesson5.png' alt=''/>
                            <div className='p-3 d-flex flex-column justify-content-between' style={{width:'70%'}}>
                                <h2 className='mb-0 txt-middle'>Блистательный сервис - 2 часть.</h2>
                                <div className='d-flex align-items-center'>
                                    <img className='ico' src='/img/knowledge-base/ico-clock-red.svg' alt=''/>
                                    <span className='ml-2 txt-small'>3 минуты</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className='ml-3 mr-3 mb-2'>
                        <Link to='/knowledge-base/category/lesson6' className='chio-card'>
                            <img className='card-img-left mr-2' src='/img/lesson/lesson6.png' alt=''/>
                            <div className='p-3 d-flex flex-column justify-content-between' style={{width:'70%'}}>
                                <h2 className='mb-0 txt-middle'>Блистательный сервис - 3 часть.</h2>
                                <div className='d-flex align-items-center'>
                                    <img className='ico' src='/img/knowledge-base/ico-clock-red.svg' alt=''/>
                                    <span className='ml-2 txt-small'>2.5 минуты</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <Menu></Menu>
            </div>
        )
    }
}

export default KnowledgeBase;