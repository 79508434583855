import React, {Component} from 'react';
import {
    Link
} from "react-router-dom";

import Menu from "../../Menu";
import PageHeader from "../../PageHeader";

export class Lesson7 extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className='main lesson'>
                <div className='content mx-w-content'>

                    <PageHeader
                        pageTitle='Урок'
                        backBtnUrl='/knowledge-base/category'/>
                    <div className='pt-header'></div>

                    <div className='pl-3 pr-3'>
                        <div className='chio-card-subheader bg-red pl-3 pr-3 pt-2 d-flex justify-content-between '>
                            <span className='text-white txt-middle'></span>
                            <span className='text-white txt-middle'>
                                <img className='ico mr-2' src='/img/knowledge-base/ico-clock-white.svg' alt=''/>
                                4 мин
                            </span>
                        </div>
                        <div className='chio-card mb-2 p-3 mb-3'>
                            <div className='txt-large'>Каскадная стрижка с градуировкой у лица. Теория.
                            </div>
                        </div>


                        <p>
                            В этом видео ты увидишь создание данной стрижки на манекене.
                        </p>
                        <p style={{overflowWrap:'break-word'}}>
                            Ссылка на видео: <a href={'https://drive.google.com/file/d/1lZYlT72qpphqT5So9QD0ofwAJqEbkOLg/view?usp=drive_link'}>https://drive.google.com/file/d/1lZYlT72qpphqT5So9QD0ofwAJqEbkOLg/view?usp=drive_link</a>

                        </p>
                    </div>

                </div>
                <Menu/>
            </div>
        )
    }
}

export default Lesson7;