import React, {Component} from 'react';
import {
    Link
} from "react-router-dom";

import Menu from "../Menu";

export class Support extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            info: '+++++++++++',
            service: [],
            dateTime: '',
            activeBlock: '',
            activeCompany: [],
            recordsPrev: [],
        };
    }

    componentDidMount() {
    }

    renderHeader() {
        return (
            <h1 className='page-header m-15 d-flex'>
                <Link to='/'>
                    <img className='mr-3' alt=''
                         src='img/icons/arrow-left.svg'/>
                </Link>
                Поддержка
            </h1>
        )
    }

    render() {
        return (
            <div className='main analitycs'>
                <div className='content mx-w-content'>

                    {this.renderHeader()}

                    <div className='pall-15 mt-4'>
                        Содержание страницы?
                    </div>
                </div>
                <Menu></Menu>
            </div>
        )


    }
}

export default Support;