import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
// import List from "../CompanyList/List";
// import MenuRecord from "../Record/MenuRecord";
// import DateTime from "../Record/DateTime/DateTime";
// import Service from "../Record/Service/Service";
// import Staff from "../Record/Staff/Staff";
// import Menu from "../Menu";
// import Order from "../Record/Order/Order";
// import GlobalVariable from "../../GlobalVariable";
// import axios from "axios";
// import Menu from "../Menu";
import PageHeader from "../PageHeader";
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";
import Preloader from "../Preloader";


export class MasterDefine extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            city: '0',
            step: 1, // 0 - загрузка
            cityArr: [],
            companyArr: [],
            isMasterFound: false,
            isChooseCity: false,
            isSendCode: false,
            addressId: null,
            pin: '',
            nameStaff: '',
            errorText: '',
            phoneStaff: '',
            phoneCode: '',
            timer: 60,
            isFindMaster: true,
            phoneCall: 'before', // 'before' | 'startLessTime'
        };
        this.changeName = this.changeName.bind(this);
        this.changePin = this.changePin.bind(this);
        this.changePhone = this.changePhone.bind(this);
        this.changeCity = this.changeCity.bind(this);
        this.changeAddress = this.changeAddress.bind(this);
        this.changePhoneCode = this.changePhoneCode.bind(this);
        this.timer = this.timer.bind(this);
    }

    changeName(e) {
        this.setState({nameStaff: e.target.value});
    }

    changePin(e) {
        this.setState({pin: e.target.value});
    }

    changePhone(e) {
        let val = e.target.value;
        val = val.replace(/[^+\d]/g, '');

        let num = `${val.substring(0, 1)} ${val.substring(1, 4)} ${val.substring(4, 7)} ${val.substring(7, val.length)}`;

        num = num.trim();

        if (num.length <= 14) {
            this.setState({phoneStaff: num});
        }
    }

    changeCity(e) {
        if (e.target.value !== 0) {
            this.setState({city: e.target.value, addressId: '0'});
        } else {
            this.setState({city: '0', addressId: '0'});
        }
    }

    changeAddress(e) {
        this.setState({addressId: e.target.value});
    }

    changePhoneCode(e) {
        this.setState({phoneCode: e.target.value});
    }

    nextStepCode() {
        this.setState({phoneCall: 'startLessTime'});
        const data = {
            phoneStaff: this.state.phoneStaff.replace(/[^0-9]/g, "")
        };
        axios({
            url: GlobalVariable.host() + '/open/widget/security/check/phone/staff2',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                if (result.data.status === 'bad') {
                    this.setState({
                        auth: false,
                        errorText: 'Произошла ошибка'
                    })
                } else {
                    this.setState({isSendCode: true});
                    if (this.state.timer > 0) {
                        setInterval(this.timer, 1000);
                    }
                }
            }).catch(error => {
            this.setState({auth: false, isLoad: true})
            // this.changeError(true)
        })

    }

    timer() {
        if (this.state.timer > 0) {
            this.setState({timer: this.state.timer - 1})
        } else {
            this.setState({phoneCall: 'before', timer: 60})
        }
    }

    auth() {
        const data = {
            'code': this.state.phoneCode,
            'phone': this.state.phoneStaff.replace(/[^0-9]/g, ""),
            'pin': this.state.pin,
            'nameStaff': this.state.nameStaff,
            'pointId': this.state.addressId,
        };


        axios({
            url: GlobalVariable.host() + '/open/mobile/security/check/staff/code2',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                    window.location.href = '/home';
                    // // if (result.data.status == 'bad') {
                    //     this.setState({+7(996)116-00-90
                    //         auth: false,
                    //         // isLoad: true,
                    //
                    //     })
                    // } else {
                    //     if (result.data.change == false) {
                    //     this.setState({
                    //         auth: true,
                    //
                    //
                    //     })
                    //     if (result.data.status == 'bad') {
                    //         this.setState({
                    //             auth: false,
                    //             step: 'phone'
                    //
                    //
                    //         })
                    //
                    //
                    //     }
                    //     if (this.state.params.url) {
                    //         window.location.href = this.state.params.url;
                    //     } else {
                    //         window.location.href = '/home';
                    //     }
                    //
                    //     // } else {
                    //     //     this.setState({
                    //     //         auth: true,
                    //     //
                    //     //
                    //     //     })
                    //     //     // window.location.href = '/profile';
                    //     // }
                    //
                    //
                    // }
                }
            ).catch(error => {
            this.setState({auth: false, isLoad: true})
            // window.location.href = '/' + this.props.widgetId + '/profile';
        })
    }

    componentDidMount() {

        let urlAPI = GlobalVariable.host() + '/open/widget/staff/v2/city';
        const data = {};
        axios({
            url: urlAPI,
            headers: {'Content-Type': 'application/json'},
            method: "GET",
            data: data,
            withCredentials: true,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                this.setState({
                    cityArr: result.data.city,
                    companyArr: result.data.company,
                    isLoad: true,
                    auth: false
                })
            }).catch(error => {
            this.setState({
                auth: false,
                isLoad: true,
            })
            // window.location.href = '/' ;
        })
    }

    addressSelection() {
        if (this.state.addressId != null) {
            this.setState({step: 2});
        }
    }

    phoneSelection() {

    }

    nameAndPinTerminal() {
        this.setState({isFindMaster: false});
        if (this.state.pin !== '' && this.state.nameStaff !== '') {
            let urlAPI = GlobalVariable.host() + '/open/widget/staff/v2/search_staff';
            const data = {
                'pin': this.state.pin,
                'nameStaff': this.state.nameStaff,
                'pointId': this.state.addressId,
            };
            axios({
                url: urlAPI,
                headers: {'Content-Type': 'application/json'},
                method: "POST",
                data: data,
                withCredentials: true,
                // receive two    parameter endpoint url ,form data
            })
                .then(result => { // then print response status
                    if (result.data.status) {
                        this.setState({
                            step: 3,
                        })
                    } else {
                        this.setState({
                            errorText: 'Возможно, Вы ошиблись. Перепроверьте данные и попробуйте снова.',
                            isFindMaster: true
                        })
                    }
                }).catch(error => {
                this.setState({
                    auth: false,
                    isLoad: true,
                    isFindMaser: false
                })
                // window.location.href = '/' ;
            })
            // this.setState({step: 2});
        }
    }


    render() {
        if (!this.state.isLoad) {
            return (
                <Preloader></Preloader>
            )
        } else {
            return (
                <div className='main want-to-work'>
                    <div className='content mx-w-content'>
                        <PageHeader
                            pageTitle='Вход в систему'
                            backBtnUrl='/login'/>
                    </div>

                    <div className='content'>
                        <div className='mx-w-content'>

                            <div className='chio-card-subheader ml-3 mr-3 pl-3 pr-3 pt-2'>
                                Вход по имени и пин-коду
                            </div>
                            <div className='chio-card ml-3 mb-2 mr-3 pl-3 pr-3 pt-2 pb-2'>


                                {this.state.step === 2 &&
                                <>
                                    <div>
                                        {/*name*/}
                                        <div className='ml-3 txt-middle mt-4 mb-1'>Имя как в терминале</div>
                                        <input
                                            required={true}
                                            value={this.state.name}
                                            placeholder={'Имя'}
                                            onChange={this.changeName}
                                            className='txt-input pl-3 pr-3 pt-2 pb-2 txt-middle mb-3 bg-white font-weight-normal'/>

                                        {/*pin*/}
                                        <div className='ml-3 txt-middle mb-1'>Пин-код как в теримнале (4 знака)</div>
                                        <input
                                            maxLength={4}
                                            minLength={4}
                                            required={true}
                                            value={this.state.pin}
                                            placeholder={'Пин-код'}
                                            onChange={this.changePin}
                                            className='txt-input pl-3 pr-3 pt-2 pb-2 txt-middle mb-3 bg-white font-weight-normal'/>
                                    </div>
                                    <p style={{color: 'red'}}>{this.state.errorText}</p>

                                    {((this.state.name !== '') && (this.state.pin.length === 4) && (this.state.isFindMaster)) ?
                                        <div onClick={() => this.nameAndPinTerminal()}
                                             className='chio-card bg-red text-white pt-3 pb-3 pl-5 pr-5 d-table m-auto mb-3'>
                                            Продолжить
                                        </div> : (
                                            (this.state.isFindMaster !== false) ?
                                                <div style={{backgroundColor: '#dbdbdb'}}
                                                     className='chio-card  pt-3 pb-3 pl-5 pr-5 d-table m-auto mb-3'>
                                                    Продолжить
                                                </div> :
                                                <div style={{backgroundColor: '#dbdbdb'}}
                                                     className='chio-card  pt-3 pb-3 pl-5 pr-5 d-table m-auto mb-3'>
                                                    <div className="spinner-border" role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </div>
                                        )
                                    }

                                </>
                                }
                                {this.state.step === 1 &&
                                <>
                                    <div className='ml-3 txt-middle mt-2 mb-3 red-text'>
                                        Мы не нашли Ваш номер телефона в системе. Для того, чтобы мы могли показать Ваши
                                        записи,
                                        укажите, пожалуйста, дополнительную информацию.
                                    </div>
                                    <div>
                                        {/*city*/}
                                        <div className='ml-3 txt-middle mt-3 mb-1'>Ваш город</div>
                                        <select
                                            defaultValue={'0'}
                                            required={true}
                                            value={this.state.city}
                                            onChange={this.changeCity}
                                            className='txt-input pl-3 pr-3 pt-2 pb-2 txt-middle mb-2 bg-white font-weight-normal'>
                                            <option value='0' disabled={true}>Не выбран</option>
                                            {/*{console.log(this.state.cityArr)}*/}
                                            {this.state.cityArr.map((itemCity) => (
                                                <option value={itemCity.id}>{itemCity.title}</option>
                                                // <option value='1'>1</option>
                                            ))}

                                        </select>

                                        {this.state.city !== '0' &&
                                        <>
                                            {/*address*/}
                                            <div className='ml-3 txt-middle mt-2 mb-1'>Ваш адрес одной из точек</div>
                                            <select
                                                defaultValue={'0'}
                                                required={true}
                                                value={this.state.addressId}
                                                onChange={this.changeAddress}
                                                className='txt-input pl-3 pr-3 pt-2 pb-2 txt-middle mb-3 bg-white font-weight-normal'>
                                                <option value='0'>Не выбран</option>

                                                {this.state.companyArr[this.state.city].map((itemPoint) => (
                                                    <option value={itemPoint.id}>{itemPoint.address}</option>
                                                ))}
                                            </select>
                                        </>
                                        }
                                    </div>
                                    {((this.state.city !== '0') && (this.state.addressId !== '0')) ?
                                        <div onClick={() => this.addressSelection()}
                                             className='chio-card bg-red text-white pt-3 pb-3 pl-5 pr-5 d-table m-auto mb-3 mt-3'>
                                            Продолжить
                                        </div> :
                                        <div style={{backgroundColor: '#dbdbdb'}}
                                             className='chio-card pt-3 pb-3 pl-5 pr-5 d-table m-auto mb-3 mt-3'>
                                            Продолжить
                                        </div>
                                    }
                                </>
                                }
                                {this.state.step === 3 && <>
                                    <div>
                                        {/*phone*/}
                                        <div className='ml-3 txt-middle mt-4 mb-1'>Введите ваш номер телефона</div>
                                        <input
                                            required={true}
                                            value={this.state.phoneStaff}
                                            placeholder={'7 (xxx) xxx-xx-xx'}
                                            onChange={this.changePhone}
                                            className='txt-input pl-3 pr-3 pt-2 pb-2 txt-middle mb-0 bg-white font-weight-normal'/>
                                        <div className='ml-3 txt-small mt-0 mb-3'>
                                            *Поступит <b>звонок</b>, робот <b>продиктует</b> код
                                        </div>
                                        {this.state.phoneStaff.length === 14 ?
                                            (this.state.phoneCall === 'startLessTime' ?
                                                    <>
                                                        <div style={{backgroundColor: '#dbdbdb'}}
                                                             className='chio-card pt-3 pb-3 pl-5 pr-5 d-table m-auto mb-3'>
                                                            Позвонить через {this.state.timer}
                                                        </div>
                                                    </> :
                                                    <>
                                                        <div onClick={() => this.nextStepCode()}
                                                             className='chio-card bg-red text-white pt-3 pb-3 pl-5 pr-5 d-table m-auto mb-3'>
                                                            Подтвердить номер
                                                        </div>
                                                    </>
                                            ) :
                                            <>
                                                <div style={{backgroundColor: '#dbdbdb'}}
                                                     className='chio-card pt-1 pb-1 pl-3 pr-3 d-table m-auto mb-3'>
                                                    Подтвердить номер
                                                </div>
                                            </>
                                        }

                                        {this.state.isSendCode === true &&
                                        <>
                                            <div className='ml-3 txt-middle mt-2 mb-0'>Код подтверждения</div>
                                            <input
                                                maxLength={4}
                                                minLength={4}
                                                required={true}
                                                value={this.state.phoneCode}
                                                placeholder={'Код, продиктованный роботом'}
                                                onChange={this.changePhoneCode}
                                                className='txt-input pl-3 pr-3 pt-2 pb-2 txt-middle mb-3 bg-white font-weight-normal'/>
                                            <div className='ml-3 txt-small mt-0 mb-3'>
                                                *Из входящего звонка, <b>продиктованный</b> роботом
                                            </div>
                                            {this.state.pin.length === 4 ?
                                                <>
                                                    <div onClick={() => this.auth()}
                                                         className='chio-card bg-red text-white pt-3 pb-3 pl-5 pr-5 d-table m-auto mb-3'>
                                                        Продолжить
                                                    </div>
                                                </> :
                                                <>
                                                    <div style={{backgroundColor: '#dbdbdb'}}
                                                         className='chio-card pt-3 pb-3 pl-5 pr-5 d-table m-auto mb-3'>
                                                        Продолжить
                                                    </div>
                                                </>
                                            }
                                        </>
                                        }
                                    </div>

                                </>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default MasterDefine;