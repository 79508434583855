import React, {Component} from 'react';
import {Link} from "react-router-dom";

export class Menu extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        return (<menu className='bottom-menu pb-5 pt-3'>
                <div className='mx-w-content menu-wrap'>
                    <Link to='/home'>
                        <img src='/img/menu/ico-m-home.svg' alt='Домой'/>
                        <div className='txt-middle dark-textc'>Главная</div>
                    </Link>
                    <Link to='/records'>
                        <img src='/img/menu/ico-m-records.svg' alt='Мои записи'/>
                        <div className='txt-middle dark-textc'>Записи</div>
                    </Link>
                    <Link to='/events'>
                        <img src='/img/menu/ico-m-events.svg' alt='События'/>
                        <div className='txt-middle dark-textc'>События</div>
                    </Link>
                </div>
            </menu>
        )
    }
}

export default Menu;