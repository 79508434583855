import React, {Component} from 'react';
import {Link} from "react-router-dom";

export class Start extends Component <any, any> {

    render() {
        return (
            <div className='main start-page'>
                <div className='mx-w-content'>
                    {/*О компании*/}
                    <div className='top-block pt-5 d-flex justify-content-evenly'>
                        <div
                            className=' ml-3 p-2 chio-card bg-red txt-middle text-white d-flex flex-column align-items-center justify-content-center text-center'>
                            постригли
                            <b className='txt-large white-text'>4,2 млн</b>
                            любимых<br/>
                            клиентов
                        </div>
                        <div
                            className='p-2 chio-card bg-red txt-middle text-white d-flex flex-column align-items-center justify-content-center text-center'>
                            более
                            <b className='txt-large white-text'>800</b>
                            точек
                        </div>
                        <div
                            className='mr-3 p-2 chio-card bg-red txt-middle text-white d-flex flex-column align-items-center justify-content-center text-center'>
                            Россия<br/>
                            Беларуссия<br/>
                            Казахстан<br/>
                            Грузия
                        </div>
                    </div>

                    {/*Название приложения*/}
                    <div className='txt-xlarge text-center mt-4 mb-2'>
                        Чио Чио Мастера
                    </div>

                    {/*Кнопка Войти*/}
                    <Link to={'/login'} className='text-center'>
                        <div className='chio-card bg-red text-white pt-3 pb-3 pl-5 pr-5 d-table m-auto'>
                            Войти
                        </div>
                    </Link>

                    {/*Кнопка Хочу работать в Чио Чио*/}
                    {/*<Link to='/want-to-work'>*/}
                    {/*    <div className='txt-middle text-center mt-3 mb-4 text-decoration-underline'>*/}
                    {/*        Хочу работать в Чио Чио*/}
                    {/*    </div>*/}
                    {/*</Link>*/}
                </div>
            </div>
        )
    }
}

export default Start;