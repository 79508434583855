import './Chat.css';

import React, {Component} from 'react';
import Menu from "../Menu";
import PageHeader from "../PageHeader";


function ChatBlock() {
    return (
        <div className='ChatBlock mx-w-content'>
            <div className='w-100 d-flex chat-input-block'>
                <textarea className='w-100 chio-card p-2'></textarea>
                <div className='d-flex align-items-center justify-content-center pl-3'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor"
                         className="bi bi-arrow-up-circle-fill" viewBox="0 0 16 16">
                        <path
                            d="M16 8A8 8 0 1 0 0 8a8 8 0 0 0 16 0m-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707z"/>
                    </svg>
                </div>
            </div>
            <div className='chat-scroll'>
                <div className='m-3 p-2 chio-card right msg'>
                    <div className='d-flex justify-content-between'>
                        <div className='txt-small'>Вы</div>
                        <div className='txt-small'>16.09.2024 18:00</div>
                    </div>
                    Спасибо, теперь проблема не актуальна!!!
                </div>
                <div className='m-3 p-1 chio-card left msg'>
                    <div className='d-flex justify-content-between'>
                        <div className='txt-small'>Чио Чио</div>
                        <div className='txt-small'>16.09.2024 17:30</div>
                    </div>
                    Добрый день! Вы уволены!
                </div>
                <div className='m-3 p-1 chio-card left msg'>
                    <div className='d-flex justify-content-between'>
                        <div className='txt-small'>Вы</div>
                        <div className='txt-small'>16.09.2024 17:00</div>
                    </div>
                    Добрый день! У меня проблема((( Закончился чай на рабочем месте.
                </div>
                <div className='m-3 p-2 chio-card right msg'>
                    <div className='d-flex justify-content-between'>
                        <div className='txt-small'>Вы</div>
                        <div className='txt-small'>16.09.2024 18:00</div>
                    </div>
                    Спасибо, теперь проблема не актуальна!!!
                </div>
                <div className='m-3 p-1 chio-card left msg'>
                    <div className='d-flex justify-content-between'>
                        <div className='txt-small'>Чио Чио</div>
                        <div className='txt-small'>16.09.2024 17:30</div>
                    </div>
                    Добрый день! Вы уволены!
                </div>
                <div className='m-3 p-1 chio-card left msg'>
                    <div className='d-flex justify-content-between'>
                        <div className='txt-small'>Вы</div>
                        <div className='txt-small'>16.09.2024 17:00</div>
                    </div>
                    Добрый день! У меня проблема((( Закончился чай на рабочем месте.
                </div>
            </div>
        </div>
    )
}

export class Chat extends Component <any, any> {
    render() {
        return (
            <div className='main knowledge-base'>
                <div className='content mx-w-content'>
                    <PageHeader
                        pageTitle='Чат с УК'
                        backBtnUrl='/'/>
                    <div className='pt-header'></div>

                    <ChatBlock/>

                </div>
                <Menu></Menu>
            </div>
        )
    }
}

export default Chat;


// <div className={'global chat'}>
//    <div style={{padding: '15px'}}>
//        <div className={'title mb-3'}>
//            Чат
//        </div>
//        <div className={'chat-body'}>
//            <img src={'/img/dev.png'}/>
//            <div className={'title'}>Данная страница в разработке, скоро она заработает</div>
//        </div>
// //
//        <div className={'small-title'}>Диалог с call - центром Чио Чио
//        </div>
//        <div className={'chat-body'}>
//            <div className={'user'}>
//                Привет!
//            </div>
//            <div className={'call-center'}>
//                Привет!
//            </div>
//            <div className={'user'}>
//                Привет!
//            </div>
//            <div className={'call-center'}>
//                Привет!
//            </div>
//            <div className={'user'}>
//                Привет!
//            </div>
//            <div className={'call-center'}>
//                Привет!
//            </div>
//        </div>
//        <div className={'keyboard'}>
//            <div className={'from'}>
//            <textarea
//                className={'input'}/>
//            </div>
//            <div className={'btn-send'}>
//                <img src={'/img/send-mess-icon.png'}/>
//            </div>
//        </div>
// //
//    </div>
//    <Menu></Menu>
// // </div>