import React, {Component} from 'react';
import {
    Link
} from "react-router-dom";

import Menu from "../../Menu";
import PageHeader from "../../PageHeader";
import GlobalVariable from "../../../GlobalVariable";
import axios from "axios";

export class Event1 extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            // events: [],
        };
    }

    componentDidMount() {
        // let urlAPI2 = GlobalVariable.staffBack() + '/getEvent/'+this.props.idEvent;
        // const data2 = {};
        // axios({
        //     url: urlAPI2,
        //     headers: {'Content-Type': 'application/json'},
        //     method: "GET",
        //     data: data2,
        //     withCredentials: true,
        //     // receive two    parameter endpoint url ,form data
        // })
        //     .then(result => { // then print response status
        //         this.setState({
        //             events: result.data,
        //         })
        //     }).catch(error => {
        //
        //
        //     // window.location.href = '/' ;
        // })
    }

    renderContent() {
        // if(this.state.events){
        return (
            <div className='pl-3 pr-3'>
                <div className='chio-card-subheader pl-3 pr-3 pt-2 d-flex justify-content-between '>
                    <span className='text-white txt-middle'></span>
                    <span className='text-white txt-middle'>
                                <img className='ico mr-2' src='/img/knowledge-base/ico-clock-white.svg' alt=''/>
                                13.09.20224
                            </span>
                </div>
                <div className='chio-card mb-2 p-3 mb-3'>
                    <div className='txt-large'>С Днем Парикмахера! Промокоды на скидки.</div>
                </div>
                <div>
                    <p>С праздником, дорогой мастер Чио Чио 🙂</p>
                    <p>В этот день хотим вручить тебе маленький подарок:</p>
                    <p> 🎉Скидку 5% на весь заказ в интернет-магазине для парикмахеров СтилистПроф</p>
                        <a href={'https://stylist-prof.ru'}>stylist-prof.ru</a>

                        <p> Уникальный промокод ChioChio</p>

                    <p>🎉Скидку 15% на курсы изучения иностранных языков для всей семьи в онлайн академии Star Talk
                        <p> <a href={'https://www.start2talk.ru/'}>start2talk.ru</a></p>
                        <p>Уникальный промокод chio-chio</p>
                    </p>
                    <p>*Промокод необходимо ввести на сайте в графе "промокод", когда перейдете к оплате. </p>
                    <p>С Днем Парикмахера ✂️</p>
                </div>

                {/*<div>*/}
                {/*    <img className='w-100'*/}
                {/*         src='/img/events/event3.jpg'/>*/}
                {/*</div>*/}

            </div>
        )
        // }

    }


    render() {
        return (
            <div className='main lesson'>
                <div className='content mx-w-content'>

                    <PageHeader
                        pageTitle='Событие'
                        backBtnUrl='/events'/>
                    <div className='pt-header'></div>

                    {this.renderContent()}

                </div>
                <Menu></Menu>
            </div>
        )
    }
}

export default Event1;