import React, {Component} from 'react';
import {
    Link
} from "react-router-dom";

import Menu from "../../Menu";
import PageHeader from "../../PageHeader";
import GlobalVariable from "../../../GlobalVariable";
import axios from "axios";

export class Event2 extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            events: [],
        };
    }

    componentDidMount() {
        // let urlAPI2 = GlobalVariable.staffBack() + '/getEvent/'+this.props.idEvent;
        // const data2 = {};
        // axios({
        //     url: urlAPI2,
        //     headers: {'Content-Type': 'application/json'},
        //     method: "GET",
        //     data: data2,
        //     withCredentials: true,
        //     // receive two    parameter endpoint url ,form data
        // })
        //     .then(result => { // then print response status
        //         this.setState({
        //             events: result.data,
        //         })
        //     }).catch(error => {
        //
        //
        //     // window.location.href = '/' ;
        // })
    }
    renderContent() {
        // if(this.state.events){
        return (
            <div className='pl-3 pr-3'>
                <div className='chio-card-subheader pl-3 pr-3 pt-2 d-flex justify-content-between '>
                    <span className='text-white txt-middle'></span>
                    <span className='text-white txt-middle'>
                                <img className='ico mr-2' src='/img/knowledge-base/ico-clock-white.svg' alt=''/>
                                7.09.20224
                            </span>
                </div>
                <div className='chio-card mb-2 p-3 mb-3'>
                    <div className='txt-large'>Прямой эфир на тему: Как подобрать стрижку под тип внешности клиента?
                    </div>
                </div>
                <p>
                    На эфире узнаете:<br/>
                    <ul>
                        <li>типы формы головы</li>
                        <li>какие стрижки подходят к женским типам внешности</li>
                        <li>какие стрижки подходят мужским типам внешности </li>
                        <li>стрижки для тех, кому за 50+</li>
                        <li>как скорректировать нюансы лица стрижкой</li>
                        <li>как подобрать идеальную для клиента стрижку</li>

                    </ul>
                </p>
                <p>
                    Приятного просмотра!
                    Ссылка на запись эфира:  <a href={'https://youtu.be/kRZIAuqH7k8'}>https://youtu.be/kRZIAuqH7k8</a>
                </p>
                {/*<div>*/}
                {/*    <img className='w-100'*/}
                {/*         src='/img/events/event3.jpg'/>*/}
                {/*</div>*/}

            </div>
        )
        // }

    }


    render() {
        return (
            <div className='main lesson'>
                <div className='content mx-w-content'>

                    <PageHeader
                        pageTitle='Событие'
                        backBtnUrl='/events'/>
                    <div className='pt-header'></div>

                    {this.renderContent()}

                </div>
                <Menu></Menu>
            </div>
        )
    }
}

export default Event2;