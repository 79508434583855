import React, {Component} from 'react';
import {Link} from "react-router-dom";


function PageHeader({pageTitle, backBtnUrl}) {
    return (
        <h1 className='page-header pb-3 mb-3 p-15 d-flex align-items-center' style={{paddingTop: '50px'}}>
            <Link to={backBtnUrl}
                  className='d-flex'>
                <img className='mr-3' alt=''
                     src='/img/home/ico-back.svg'/>
            </Link>
            <span className='txt-large'>{pageTitle}</span>
        </h1>
    );
}

export default PageHeader;
