import React, {Component} from 'react';
import Menu from "../Menu";
import PageHeader from "../PageHeader";


export class Schedule extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }


    render() {
        return (
            <div className='main'>
                <div className='content mx-w-content'>
                    <PageHeader
                        pageTitle='График работы'
                        backBtnUrl='/'/>
                    <div className='pt-header'></div>

                    <div className='pl-3 pr-3 text-center mt-5'>
                        Страница в разработке
                    </div>

                </div>
                <Menu></Menu>
            </div>
        )
    }
}

export default Schedule;