import React, {Component} from 'react';
import {
    Link
} from "react-router-dom";

import Menu from "../../Menu";
import PageHeader from "../../PageHeader";

export class KnowledgeBase3 extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className='main knowledge-base'>
                <div className='content mx-w-content'>
                    <PageHeader
                        pageTitle='Список уроков'
                        backBtnUrl='/knowledge-base'/>
                    <div className='pt-header'></div>

                    <div className='ml-3 mr-3 mb-2'>
                        <Link to='/knowledge-base/category/lesson7' className='chio-card'>
                            <img className='card-img-left mr-2' src='/img/lesson/lesson7.png' alt=''/>
                            <div className='p-3 d-flex flex-column justify-content-between' style={{width:'70%'}}>
                                <h2 className='mb-0 txt-middle'>Каскадная стрижка с градуировкой у лица. Теория.</h2>
                                <div className='d-flex align-items-center'>
                                    <img className='ico' src='/img/knowledge-base/ico-clock-red.svg' alt=''/>
                                    <span className='ml-2 txt-small'>4 минуты</span>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className='ml-3 mr-3 mb-2'>
                        <Link to='/knowledge-base/category/lesson13' className='chio-card'>
                            <img className='card-img-left mr-2' src='/img/lesson/lesson8.png' alt=''/>
                            <div className='p-3 d-flex flex-column justify-content-between' style={{width:'70%'}}>
                                <h2 className='mb-0 txt-middle'>Каскадная стрижка с градуировкой у лица. Практика.</h2>
                                <div className='d-flex align-items-center'>
                                    <img className='ico' src='/img/knowledge-base/ico-clock-red.svg' alt=''/>
                                    <span className='ml-2 txt-small'>4 минуты</span>
                                </div>
                            </div>
                        </Link>
                    </div>

                    <div className='ml-3 mr-3 mb-2'>
                        <Link to='/knowledge-base/category/lesson9' className='chio-card'>
                            <img className='card-img-left mr-2' src='/img/lesson/lesson9.png' alt=''/>
                            <div className='p-3 d-flex flex-column justify-content-between' style={{width:'70%'}}>
                                <h2 className='mb-0 txt-middle'>Каскадная стрижка с градуировкой по периметру. Теория.</h2>
                                <div className='d-flex align-items-center'>
                                    <img className='ico' src='/img/knowledge-base/ico-clock-red.svg' alt=''/>
                                    <span className='ml-2 txt-small'>5 минут</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className='ml-3 mr-3 mb-2'>
                        <Link to='/knowledge-base/category/lesson10' className='chio-card'>
                            <img className='card-img-left mr-2' src='/img/lesson/lesson10.png' alt=''/>
                            <div className='p-3 d-flex flex-column justify-content-between' style={{width:'70%'}}>
                                <h2 className='mb-0 txt-middle'>Каскадная стрижка с градуировкой по периметру. Практика.</h2>
                                <div className='d-flex align-items-center'>
                                    <img className='ico' src='/img/knowledge-base/ico-clock-red.svg' alt=''/>
                                    <span className='ml-2 txt-small'>10 минут</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className='ml-3 mr-3 mb-2'>
                        <Link to='/knowledge-base/category/lesson11' className='chio-card'>
                            <img className='card-img-left mr-2' src='/img/lesson/lesson11.png' alt=''/>
                            <div className='p-3 d-flex flex-column justify-content-between' style={{width:'70%'}}>
                                <h2 className='mb-0 txt-middle'>Каре с низкой градуировкой. Теория</h2>
                                <div className='d-flex align-items-center'>
                                    <img className='ico' src='/img/knowledge-base/ico-clock-red.svg' alt=''/>
                                    <span className='ml-2 txt-small'>5 минут</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className='ml-3 mr-3 mb-2'>
                        <Link to='/knowledge-base/category/lesson12' className='chio-card'>
                            <img className='card-img-left mr-2' src='/img/lesson/lesson12.png' alt=''/>
                            <div className='p-3 d-flex flex-column justify-content-between' style={{width:'70%'}}>
                                <h2 className='mb-0 txt-middle'>Каре с низкой градуировкой. Практика.</h2>
                                <div className='d-flex align-items-center'>
                                    <img className='ico' src='/img/knowledge-base/ico-clock-red.svg' alt=''/>
                                    <span className='ml-2 txt-small'>12 минут</span>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className='ml-3 mr-3 mb-2'>
                        <Link to='/knowledge-base/category/lesson13' className='chio-card'>
                            <img className='card-img-left mr-2' src='/img/lesson/lesson12.png' alt=''/>
                            <div className='p-3 d-flex flex-column justify-content-between' style={{width:'70%'}}>
                                <h2 className='mb-0 txt-middle'>Каре с низкой градуировкой. Практика.</h2>
                                <div className='d-flex align-items-center'>
                                    <img className='ico' src='/img/knowledge-base/ico-clock-red.svg' alt=''/>
                                    <span className='ml-2 txt-small'>13 минут</span>
                                </div>
                            </div>
                        </Link>
                    </div>

                </div>
                <Menu></Menu>
            </div>
        )
    }
}

export default KnowledgeBase3;