import React, {Component} from 'react';
import {
    Link
} from "react-router-dom";

import Menu from "../../Menu";
import PageHeader from "../../PageHeader";
import GlobalVariable from "../../../GlobalVariable";
import axios from "axios";

export class Event3 extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            events: [],
        };
    }

    componentDidMount() {

    }

    renderContent() {
        // if(this.state.events){
        return (
            <div className='pl-3 pr-3'>
                <div className='chio-card-subheader pl-3 pr-3 pt-2 d-flex justify-content-between '>
                    <span className='text-white txt-middle'></span>
                    <span className='text-white txt-middle'>
                        <img className='ico mr-2' src='/img/knowledge-base/ico-clock-white.svg' alt=''/>
                        10.09.20224
                    </span>
                </div>
                <div className='chio-card mb-2 p-3 mb-3'>
                    <div className='txt-large'>Прямой эфир на тему: Как общаться с посетителем, чтобы он стал твоим
                        постоянным клиентом?
                    </div>
                </div>
                <p>
                    На эфире обсудили подробно каждый этап взаимодействия с посетителем:
                    <br/>
                    <ul>
                        <li>приветствие</li>
                        <li>установление доверия</li>
                        <li>выявление потребности</li>
                        <li>отработка возражения</li>
                        <li>завершение общения</li>


                    </ul>
                </p>
                <p> Отдельно обсудили правила поведения мастера в форс-мажорных ситуациях (пожар, неадекватный
                    посетитель). </p>
                <p>За время просмотра эфира вы будете точно знать, как вести себя с клиентом в той или иной ситуации,
                    будете знать свои права и права клиента.
                </p>
                <p>
                    Приятного просмотра!
                    Ссылка на запись эфира: <a href={'https://youtu.be/OQ6MQkHwEhU'}>https://youtu.be/OQ6MQkHwEhU</a>
                </p>
                {/*<div>*/}
                {/*    <img className='w-100'*/}
                {/*         src='/img/events/event3.jpg'/>*/}
                {/*</div>*/}

            </div>
        )
        // }

    }


    render() {
        return (
            <div className='main lesson'>
                <div className='content mx-w-content'>

                    <PageHeader
                        pageTitle='Событие'
                        backBtnUrl='/events'/>
                    <div className='pt-header'></div>

                    {this.renderContent()}

                </div>
                <Menu></Menu>
            </div>
        )
    }
}

export default Event3;