import React, {Component} from 'react';
import {
    Link
} from "react-router-dom";

import Menu from "../Menu";
import PageHeader from "../PageHeader";

export class Lesson extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className='main lesson'>
                <div className='content mx-w-content'>

                    <PageHeader
                        pageTitle='Урок'
                        backBtnUrl='/knowledge-base/category'/>
                    <div className='pt-header'></div>

                    <div className='pl-3 pr-3'>
                        <div className='chio-card-subheader bg-red pl-3 pr-3 pt-2 d-flex justify-content-between '>
                            <span className='text-white txt-middle'></span>
                            <span className='text-white txt-middle'>
                                <img className='ico mr-2' src='/img/knowledge-base/ico-clock-white.svg' alt=''/>
                                2 мин
                            </span>
                        </div>
                        <div className='chio-card mb-2 p-3 mb-3'>
                            <div className='txt-large'>История компании Чио&nbsp;Чио</div>
                        </div>

                        <p>
                            Приветствуем тебя, мастер Чио&nbsp;Чио.
                        </p>
                        <p>
                            В этом видео ты узнаешь:<br/>
                            <ul>
                                <li>кто является основателем компании Чио&nbsp;Чио</li>
                                <li>как и когда открылась первая парикмахерская</li>
                                <li>почему Чио&nbsp;Чио - экспресс-парикмахерская</li>
                            </ul>
                        </p>
                        <div>
                            <img className='w-100'
                                 src='/img/knowledge-base/video.png'/>
                        </div>
                    </div>

                </div>
                <Menu/>
            </div>
        )
    }
}

export default Lesson;