import React, {Component} from 'react';
import {
    Link
} from "react-router-dom";

import Menu from "../Menu";
import PageHeader from "../PageHeader";

export class KnowledgeCategories extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className='main knowledge-categories'>
                <div className='content mx-w-content'>

                    <PageHeader
                        pageTitle='База знаний'
                        backBtnUrl='/'/>
                    <div className='pt-header'></div>

                    <Link to='/knowledge-base/category1'>
                        <div className='ml-3 mr-3 mb-2'>
                            <div className='chio-card-subheader bg-red d-flex justify-content-between pl-3 pr-3 pt-2'>
                                <div className='text-white'>О компании</div>
                                <div className='text-white'>1 урок</div>
                            </div>
                            <div className='chio-card'>
                                <img className='card-img-wh' src='/img/knowledge-base/base-cakegory1.jpg' alt=''/>
                                <div className='more text-white txt-middle position-absolute'>Подробнее</div>
                            </div>
                        </div>
                    </Link>

                    <Link to='/knowledge-base/category2'>
                        <div className='ml-3 mr-3 mb-2'>
                            <div className='chio-card-subheader bg-red d-flex justify-content-between pl-3 pr-3 pt-2'>
                                <div className='text-white'>Сервис</div>
                                <div className='text-white'>5 уроков</div>
                            </div>
                            <div className='chio-card'>
                                <img className='card-img-wh' src='/img/knowledge-base/base-cakegory2.jpg' alt=''/>
                                <div className='more text-white txt-middle position-absolute'>Подробнее</div>
                            </div>
                        </div>
                    </Link>

                    <Link to='/knowledge-base/category3'>
                        <div className='ml-3 mr-3 mb-2'>
                            <div className='chio-card-subheader bg-red d-flex justify-content-between pl-3 pr-3 pt-2'>
                                <div className='text-white'>Стрижки женские</div>
                                <div className='text-white'>7 уроков</div>
                            </div>
                            <div className='chio-card'>
                                <img className='card-img-wh' src='/img/knowledge-base/base-cakegory3.jpg' alt=''/>
                                <div className='more text-white txt-middle position-absolute'>Подробнее</div>
                            </div>
                        </div>
                    </Link>

                    {/*<Link to='/knowledge-base/category'>*/}
                    {/*    <div className='ml-3 mr-3 mb-2'>*/}
                    {/*        <div className='chio-card-subheader bg-red d-flex justify-content-between pl-3 pr-3 pt-2'>*/}
                    {/*            <div className='text-white'>Стрижки мужские</div>*/}
                    {/*            <div className='text-white'>4 урока</div>*/}
                    {/*        </div>*/}
                    {/*        <div className='chio-card'>*/}
                    {/*            <img className='card-img-wh' src='/img/knowledge-base/base-cakegory4.jpg' alt=''/>*/}
                    {/*            <div className='more text-white txt-middle position-absolute'>Подробнее</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</Link>*/}
                </div>
                <Menu></Menu>
            </div>
        )
    }
}

export default KnowledgeCategories;