import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
// import List from "../CompanyList/List";
// import MenuRecord from "../Record/MenuRecord";
// import DateTime from "../Record/DateTime/DateTime";
// import Service from "../Record/Service/Service";
// import Staff from "../Record/Staff/Staff";
// import Menu from "../Menu";
// import Order from "../Record/Order/Order";
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";
import Menu from "../Menu";
import Preloader from "../Preloader";
import PageHeader from "../PageHeader";


export class MyRecord extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            staff: [],
            service: [],
            dateTime: [],
            activeBlock: '',
            activeCompany: [],
            recordsPrev: [],
            recordsNext: [],
            recordsNext2: [],
            isLoad: false,
            auth: true,
            activeRecord: '',
            balls: 0,

            isArchive: false
        };


    }


    componentDidMount() {
        const data = {};
        axios({
            url: GlobalVariable.host() + '/mobile/profile/my-record',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "GET",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                this.setState({
                    recordsPrev: result.data.prevRecord,
                    recordsNext: result.data.nextRecord,
                    balls: result.data.balls,
                    isLoad: true,
                    auth: true
                })
            }).catch(error => {
            this.setState({auth: false, isLoad: false})
            window.location.href = '/login';
        })


        let urlAPI3 = GlobalVariable.host() + '/widget/staff/v1/profile/my-record/next';
        var date = new Date();

        function leadingZero(token) {
            return ("0" + token).slice(-2)
        }

        var year = date.getFullYear(),
            month = leadingZero(date.getMonth() + 1), // подсчет начинается с 0, поэтому увеличим на 1
            day = leadingZero(date.getDate()),
            hours = leadingZero(date.getHours()),
            minutes = leadingZero(date.getMinutes()),
            seconds = leadingZero(date.getSeconds())


        var result = `${year}-${month}-${day} ${hours}:${minutes}`;
        console.log(result);
        const data3 = {
            'dateTime': result,
            'limit': 100,
        };
        axios({
            url: urlAPI3,
            headers: {'Content-Type': 'application/json'},
            method: "post",
            data: data3,
            withCredentials: true,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                console.log(result.data)
                this.setState({
                    recordsNext2: result.data,
                })
            }).catch(error => {

            this.setState({
                auth: false,
                isLoad: true,
            })
            // window.location.href = '/' ;
        })
    }

    renderNextRecord(item) {
        if (this.state.activeRecord == item.id) {
            return (
                <tr onClick={() => this.setState({activeRecord: item.id == this.state.activeRecord ? '' : item.id})}
                    style={{cursor: 'pointer', height: '100px'}}>
                    <th>{item.date} {item.time}</th>
                    <td>{item['service'].map((itemService) => (
                        <div>{itemService.serviceName}</div>
                    ))}</td>
                    <td className={'d-flex'}>{item.paid}&nbsp;₽
                        <div onClick={() => this.deleteRecord(item.id)} style={{
                            backgroundColor: 'red',
                            height: '70px',
                            width: '100px',
                            margin: '5px',
                            borderRadius: '15px',
                            marginLeft: '15px'
                        }}>

                            <div style={{paddingTop: '10px'}}>
                                <div style={{margin: '0 auto', width: 'fit-content'}}><img
                                    src={'/img/Trush_Icon_UIA.png'}/></div>
                                <div style={{margin: '0 auto', color: 'white', width: 'fit-content'}}>Отменить</div>
                            </div>

                        </div></td>

                </tr>
            )
        } else {
            return (
                <tr onClick={() => this.setState({activeRecord: item.id})} style={{cursor: 'pointer'}}>
                    <th>{item.date} {item.time}</th>
                    <td>{item['service'].map((itemService) => (
                        <div>{itemService.serviceName}</div>
                    ))}</td>
                    <td>{item.paid} ₽</td>

                </tr>
            )
        }
    }

    deleteRecord(idRecord) {
        // console.log(idRecord)
        const data = {
            idRecord: idRecord
        };
        axios({
            url: GlobalVariable.host() + '/widget/profile/record/delete',
            headers: {'Content-Type': 'application/json'},
            withCredentials: true,
            method: "POST",
            data: data,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                this.setState({})
            }).catch(error => {
            this.setState({auth: false, isLoad: true})
        })
    }

    changeIsArchive() {
        this.setState({isLoad: false})
        let urlAPI2 = GlobalVariable.host() + '/widget/staff/v1/profile/my-record/prev';
        var date = new Date();

        function leadingZero2(token) {
            return ("0" + token).slice(-2)
        }

        var year = date.getFullYear(),
            month = leadingZero2(date.getMonth() + 1), // подсчет начинается с 0, поэтому увеличим на 1
            day = leadingZero2(date.getDate()),
            hours = leadingZero2(date.getHours()),
            minutes = leadingZero2(date.getMinutes()),
            seconds = leadingZero2(date.getSeconds())


        var result = `${year}-${month}-${day} ${hours}:${minutes}`;
        console.log(result);
        const data2 = {
            'dateTime': result,
            'limit': 100,
        };
        axios({
            url: urlAPI2,
            headers: {'Content-Type': 'application/json'},
            method: "post",
            data: data2,
            withCredentials: true,
            // receive two    parameter endpoint url ,form data
        })
            .then(result => { // then print response status
                console.log(result.data)
                this.setState({
                    recordsPrev2: result.data,
                    isArchive: !this.state.isArchive,
                    isLoad: true
                })
            }).catch(error => {

            this.setState({
                auth: false,
                isLoad: true,
            })
            // window.location.href = '/' ;
        })

    }

    renderNotRecord() {
        if (this.state.recordsNext2.length === 0) {
            return (
                <div className='w-100 h-100 d-flex align-items-center justify-content-center'>
                    Записей не найдено
                </div>
            )
        }
    }

    render() {
        let services = this.state.services;
        if (!this.state.isLoad) {
            return (
                <Preloader></Preloader>
            )
        } else {
            if (!this.state.isArchive) {
                return (
                    <div className='main my-record'>
                        <div className='content mx-w-content'>
                            <PageHeader
                                pageTitle='Мои записи'
                                backBtnUrl='/'/>
                            <div className='pt-header'></div>
                            {/*Будущие или прошлые записи*/}
                            <div className='ml-3 mr-3 d-flex justify-content-between'>
                                <div className='p-3 p-sides chio-mark txt-large w-50 text-center'>
                                    Будущие
                                </div>
                                <div onClick={() => {
                                    this.changeIsArchive()
                                }} className='p-3 txt-large chio-mark-back w-50'>
                                    Прошлые
                                </div>
                            </div>
                            {/*Заголовок*/}
                            <div
                                className='chio-card-subheader ml-3 mr-3 pl-3 pr-3 pt-2 d-flex justify-content-between '>
                                <table className='table'>
                                    <tr>
                                        <td className='text-white txt-middle pt-0 col-2 pl-0 border-top-0'>Дата</td>
                                        <td className='text-white txt-middle pt-0 col-2 border-top-0'>Услуга</td>
                                        <td className='text-white txt-middle pt-0 col-1 pr-0  border-top-0'>Стоимость</td>
                                    </tr>
                                </table>
                            </div>
                            {/*Дата*/}
                            <div
                                className='chio-card-subheader bg-shadow ml-3 mr-3 pl-3 pr-3 pt-2 dark-textc txt-middle'>
                                {this.renderNotRecord()}
                            </div>

                            {(this.state.recordsNext2.length !== 0) &&
                            <div className='chio-card ml-3 mr-3 mb-2 pl-3 pr-3 pt-2 pb-2 bg-white'>
                                <table className='table'>
                                    {this.state.recordsNext2.map((itemRecord) => (
                                        <tr>
                                            <td className='dark-textc txt-middle col-2 pl-0'>
                                                {itemRecord.date} {itemRecord.time}
                                                <div className='txt-small p-0'>{itemRecord.client}</div>
                                                <div className='txt-small p-0'></div>
                                            </td>
                                            <td className='dark-textc txt-small col-2'>
                                                {itemRecord.service}
                                            </td>
                                            <td className='dark-textc txt-middle pr-0 col-1'>
                                                {itemRecord.paid}р
                                            </td>
                                        </tr>
                                    ))}

                                </table>
                            </div>
                            }


                            {/*<div className='text-center txt-middle bold'>*/}
                            {/*    Показать больше*/}
                            {/*</div>*/}

                            {/*<div className={'list-my-record'}>*/}
                            {/*    <div className={'d-flex mt-3'}>*/}
                            {/*        <div><img style={{marginBottom: '3px'}} className="mr-3"*/}
                            {/*                  src={"/img/Ellipse1.png"}/></div>*/}
                            {/*        <div style={{color: '#0D263F', marginLeft: '10px'}}>Будущая запись</div>*/}
                            {/*    </div>*/}
                            {/*    <div className={'d-flex mt-3 mb-3'}>*/}
                            {/*        <div><img style={{marginBottom: '3px'}} className="mr-3"*/}
                            {/*                  src={"/img/Ellipse2.png"}/></div>*/}
                            {/*        <div style={{color: '#939393', marginLeft: '10px'}}>Прошлая запись</div>*/}
                            {/*    </div>*/}
                            {/*    <table className="table">*/}
                            {/*        <thead>*/}
                            {/*        <tr>*/}
                            {/*            <th scope="col">Дата</th>*/}
                            {/*            <th scope="col">Услуги</th>*/}
                            {/*            <th scope="col">Стоимость</th>*/}

                            {/*        </tr>*/}
                            {/*        </thead>*/}
                            {/*        <tbody>*/}
                            {/*        {*/}
                            {/*            this.state.recordsNext.map((item) => (*/}

                            {/*                this.renderNextRecord(item)*/}
                            {/*            ))*/}
                            {/*        }*/}
                            {/*        {*/}
                            {/*            this.state.recordsPrev.map((item) => (*/}
                            {/*                <tr*/}
                            {/*                    className={'prevRecord'}>*/}
                            {/*                    <th>{item.date} {item.time}</th>*/}
                            {/*                    <td>{item['service'].map((itemService) => (*/}
                            {/*                        <div>{itemService.serviceName}</div>*/}
                            {/*                    ))}</td>*/}
                            {/*                    <td>{item.paid} ₽</td>*/}

                            {/*                </tr>*/}
                            {/*            ))*/}
                            {/*        }*/}

                            {/*        </tbody>*/}
                            {/*    </table>*/}

                            {/*</div>*/}

                            {/*<Link to={'/point'} className={'d-flex'} style={{justifyContent: 'center'}}>*/}
                            {/*    <div className={'btn-active mt-3 mb-3 '}>*/}
                            {/*        Продолжить*/}
                            {/*    </div>*/}
                            {/*</Link>*/}

                        </div>
                        <Menu></Menu>
                    </div>
                )
            } else {
                return (
                    <div className='main my-record'>
                        <div className='content mx-w-content'>
                            <PageHeader
                                pageTitle='Мои записи'
                                backBtnUrl='/'/>
                            <div className='pt-header'></div>
                            {/*Будущие или прошлые записи*/}
                            <div className='ml-3 mr-3 d-flex justify-content-between'>
                                <div className='p-3 p-sides chio-mark txt-large w-50 text-center'>
                                    Прошлые
                                </div>
                                <div onClick={() => {
                                    this.changeIsArchive()
                                }} className='p-3 txt-large chio-mark-back w-50'>
                                    Будущие
                                </div>
                            </div>
                            {/*Заголовок*/}
                            <div
                                className='chio-card-subheader ml-3 mr-3 pl-3 pr-3 pt-2 d-flex justify-content-between '>
                                <table className='table'>
                                    <tr>
                                        <td className='text-white txt-middle pt-0 col-2 pl-0 border-top-0'>Дата</td>
                                        <td className='text-white txt-middle pt-0 col-2 border-top-0'>Услуга</td>
                                        <td className='text-white txt-middle pt-0 col-1 pr-0  border-top-0'>Стоимость</td>
                                    </tr>
                                </table>
                            </div>
                            {/*Дата*/}
                            {/*<div*/}
                            {/*    className='chio-card-subheader bg-shadow ml-3 mr-3 pl-3 pr-3 pt-2 dark-textc txt-middle'>*/}
                            {/*    9.09.20245*/}
                            {/*</div>*/}
                            {/*Список услуг в конкретную дату*/}
                            <div className='chio-card ml-3 mr-3 mb-2 pl-3 pr-3 pt-2 pb-2 bg-white'>
                                <table className='table'>
                                    {this.state.recordsPrev2.map((itemRecord) => (
                                        <tr>
                                            <td className='dark-textc txt-middle col-2 pl-0'>
                                                {itemRecord.date} {itemRecord.time}
                                                <div className='txt-small p-0'>{itemRecord.client}</div>
                                                <div className='txt-small p-0'></div>
                                            </td>
                                            <td className='dark-textc txt-small col-2'>
                                                {itemRecord.service}
                                            </td>
                                            <td className='dark-textc txt-middle pr-0 col-1'>
                                                {itemRecord.paid}р
                                            </td>
                                        </tr>
                                    ))}
                                </table>
                            </div>
                            {/*/!*Дата*!/*/}
                            {/*<div*/}
                            {/*    className='chio-card-subheader bg-shadow ml-3 mr-3 pl-3 pr-3 pt-2 dark-textc txt-middle'>*/}
                            {/*    9.09.2024*/}
                            {/*</div>*/}
                            {/*/!*Список услуг в конкретную дату*!/*/}
                            {/*<div className='chio-card ml-3 mr-3 mb-2 pl-3 pr-3 pt-2 pb-2 bg-white'>*/}
                            {/*    <table className='table'>*/}
                            {/*        <tr>*/}
                            {/*            <td className='dark-textc txt-middle col-2 pl-0'>*/}
                            {/*                12:00*/}
                            {/*                <div className='txt-small p-0'>Александра</div>*/}
                            {/*                <div className='txt-small p-0'>ул. Лермонтова 25</div>*/}
                            {/*            </td>*/}
                            {/*            <td className='dark-textc txt-small col-2'>*/}
                            {/*                Имиджевая стрижка*/}
                            {/*            </td>*/}
                            {/*            <td className='dark-textc txt-middle pr-0 col-1'>*/}
                            {/*                3000р*/}
                            {/*            </td>*/}
                            {/*        </tr>*/}
                            {/*        <tr>*/}
                            {/*            <td className='dark-textc txt-middle col-2 pl-0 '>*/}
                            {/*                14:0*/}
                            {/*                <div className='txt-small p-0'>Петя</div>*/}
                            {/*                <div className='txt-small p-0'>ул. Лермонтова 25</div>*/}
                            {/*            </td>*/}
                            {/*            <td className='dark-textc txt-small col-2 '>*/}
                            {/*                Уходовая процедура (30-50см)*/}
                            {/*            </td>*/}
                            {/*            <td className='dark-textc txt-middle pr-0 col-1 '>*/}
                            {/*                5000р*/}
                            {/*            </td>*/}
                            {/*        </tr>*/}
                            {/*    </table>*/}
                            {/*</div>*/}

                            {/*<div className='text-center txt-middle bold'>*/}
                            {/*    Показать больше*/}
                            {/*</div>*/}

                            {/*<div className={'list-my-record'}>*/}
                            {/*    <div className={'d-flex mt-3'}>*/}
                            {/*        <div><img style={{marginBottom: '3px'}} className="mr-3"*/}
                            {/*                  src={"/img/Ellipse1.png"}/></div>*/}
                            {/*        <div style={{color: '#0D263F', marginLeft: '10px'}}>Будущая запись</div>*/}
                            {/*    </div>*/}
                            {/*    <div className={'d-flex mt-3 mb-3'}>*/}
                            {/*        <div><img style={{marginBottom: '3px'}} className="mr-3"*/}
                            {/*                  src={"/img/Ellipse2.png"}/></div>*/}
                            {/*        <div style={{color: '#939393', marginLeft: '10px'}}>Прошлая запись</div>*/}
                            {/*    </div>*/}
                            {/*    <table className="table">*/}
                            {/*        <thead>*/}
                            {/*        <tr>*/}
                            {/*            <th scope="col">Дата</th>*/}
                            {/*            <th scope="col">Услуги</th>*/}
                            {/*            <th scope="col">Стоимость</th>*/}

                            {/*        </tr>*/}
                            {/*        </thead>*/}
                            {/*        <tbody>*/}
                            {/*        {*/}
                            {/*            this.state.recordsNext.map((item) => (*/}

                            {/*                this.renderNextRecord(item)*/}
                            {/*            ))*/}
                            {/*        }*/}
                            {/*        {*/}
                            {/*            this.state.recordsPrev.map((item) => (*/}
                            {/*                <tr*/}
                            {/*                    className={'prevRecord'}>*/}
                            {/*                    <th>{item.date} {item.time}</th>*/}
                            {/*                    <td>{item['service'].map((itemService) => (*/}
                            {/*                        <div>{itemService.serviceName}</div>*/}
                            {/*                    ))}</td>*/}
                            {/*                    <td>{item.paid} ₽</td>*/}

                            {/*                </tr>*/}
                            {/*            ))*/}
                            {/*        }*/}

                            {/*        </tbody>*/}
                            {/*    </table>*/}

                            {/*</div>*/}

                            {/*<Link to={'/point'} className={'d-flex'} style={{justifyContent: 'center'}}>*/}
                            {/*    <div className={'btn-active mt-3 mb-3 '}>*/}
                            {/*        Продолжить*/}
                            {/*    </div>*/}
                            {/*</Link>*/}

                        </div>
                        <Menu></Menu>
                    </div>
                )
            }
        }

    }
}

export default MyRecord;