import React, {Component} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
// import List from "../CompanyList/List";
// import MenuRecord from "../Record/MenuRecord";
// import DateTime from "../Record/DateTime/DateTime";
// import Service from "../Record/Service/Service";
// import Staff from "../Record/Staff/Staff";
// import Menu from "../Menu";
// import Order from "../Record/Order/Order";
import GlobalVariable from "../../GlobalVariable";
import axios from "axios";
import Menu from "../Menu";
import PageHeader from "../PageHeader";
import Preloader from "../Preloader";


export class Notifications extends Component <any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            chat: [],
            isLoad: true
        };

        this.changeName = this.changeName.bind(this);
        this.changeDate = this.changeDate.bind(this);
        this.changeEmail = this.changeEmail.bind(this);


    }


    changeName(event) {
        this.setState({name: event.target.value});
    }

    changeDate(event) {
        this.setState({date: event.target.value});
    }

    changeEmail(event) {
        this.setState({email: event.target.value});
    }


    componentDidMount() {
        // const data = {
        //     code: '1090',
        //     phone: '79625887109'
        // };
        //
        //
        // const data2 = {};
        //
        // axios({
        //
        //     url: GlobalVariable.host() + '/widget/profile/my-record',
        //     headers: {'Content-Type': 'application/json'},
        //     withCredentials: true,
        //     method: "GET",
        //     data: data2,
        //     // receive two    parameter endpoint url ,form data
        // })
        //     .then(result => { // then print response status
        //         this.setState({
        //             recordsPrev: result.data.prevRecord,
        //             recordsNext: result.data.nextRecord,
        //             isLoad: true,
        //             auth: true,
        //             info: 'Двойной Успех',
        //         })
        //     }).catch(error => {
        //     this.setState({auth: false, isLoad: true})
        //     // window.location.href = '/' + this.props.widgetId + '/profile';
        // })
    }


    render() {
        if (!this.state.isLoad) {
            return (
                <Preloader></Preloader>
            )
        } else {
            return (
                <div className='main'>
                    <div className='content mx-w-content'>
                        <PageHeader
                            pageTitle='Уведомления'
                            backBtnUrl='/'/>
                        <div className='pt-header'></div>
                        <div className='txt-middle text-center mt-5 bold font-weight-light'>Уведомлений пока нет</div>

                        {/*<div className='pl-3 pr-3 '>*/}
                        {/*    <div className='chio-card mb-2 pl-3 pr-3 pt-2 pb-2'>*/}
                        {/*        <div className='txt-middle d-flex justify-content-end'>13.09.2024 11:13 мск</div>*/}
                        {/*        <div className='txt-middle bold'>Новый отзыв</div>*/}
                        {/*        <div className='txt-middle'>5/5, ваш рейтинг: 10. Комментарий: "Стрижка - просто супер" </div>*/}
                        {/*    </div>*/}

                        {/*    <div className='chio-card mb-2 pl-3 pr-3 pt-2 pb-2'>*/}
                        {/*        <div className='txt-middle d-flex justify-content-end'>11.09.2024 15:23 мск</div>*/}
                        {/*        <div className='txt-middle bold'>Отмена записи</div>*/}
                        {/*        <div className='txt-middle'>15.09.2024 9:00, Окрашивание</div>*/}
                        {/*    </div>*/}

                        {/*    <div className='chio-card mb-2 pl-3 pr-3 pt-2 pb-2'>*/}
                        {/*        <div className='txt-middle d-flex justify-content-end'>10.09.2024 11:13 мск</div>*/}
                        {/*        <div className='txt-middle bold'>Новая запись</div>*/}
                        {/*        <div className='txt-middle'>15.09.2024 9:00, Окрашивание, 2000р, Светлана</div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                    <Menu></Menu>
                </div>
            )
        }
    }
}

export default Notifications;